import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { format } from "date-fns";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SourceList = makeShortcode("SourceList");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1><a parentName="h1" {...{
        "href": "#%E6%9E%84%E5%BB%BA%E5%B7%A5%E5%85%B7%E7%B3%BB%E5%88%97"
      }}>{`构建工具 系列`}</a></h1>
    <SourceList filter="build" mdxType="SourceList">
  {build => <ul>
      {build.filter(edge => !edge.node.frontmatter.isPrivate).reduce((routes, route) => {
          return route.node.frontmatter.pinned ? [route, ...routes] : [...routes, route];
        }, []).map((edge, index) => {
          const {
            featuredImageUrl,
            frontmatter: {
              title,
              featuredImage,
              tags,
              date,
              pinned
            },
            excerpt,
            fields: {
              slug
            }
          } = edge.node;
          return <li>
              <Link href={slug} sx={{
              textDecoration: "none",
              display: "flex",
              flex: "1 1 auto",
              flexDirection: "column",
              fontSize: "18px",
              fontWeight: "bold",
              minHeight: "1px"
            }} mdxType="Link">
                {title}
              </Link>
            </li>;
        })}
    </ul>}
    </SourceList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      